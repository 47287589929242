<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <link-modal
      v-if="chatData"
      :store-module-name="STORE_MODULE_NAME"
      :data-id="dataId"
      :user-id="chatData.userInfo.userId"
      :is-link-modal-active.sync="isLinkModalActive"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t('Show') }}</label>
              <label class="text-muted ml-2 mr-2">{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t('of') }} {{ queriedItems }}</label>
              <label for="">{{ $t('entries') }}</label>

              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="select-size-sm"
                @input="pageSize"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <label>{{ $t('Member') }}</label>
              <b-row>
                <b-col cols="11">
                  <v-select
                    v-model="userId"
                    size="sm"
                    label="name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userData"
                    :reduce="userData => userData._id"
                    :clearable="true"
                    class="select-size-sm"
                    @search="onSearchUser"
                  >
                    <div
                      slot="list-footer"
                      class="m-1"
                    >
                      <b-row>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="currentPageUser <= 1 ? true : false"
                            @click="nextPageUser(currentPageUser - 1)"
                          >
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            block
                            disabled
                          >
                            {{ currentPageUser }}
                          </b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="currentPageUser * perPageUser >= queriedItemsUser
                              ? true
                              : false
                            "
                            @click="nextPageUser(currentPageUser + 1)"
                          >
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </v-select>
                </b-col>
                <b-col cols="1">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="d-flex"
                    size="sm"
                    @click="createChat()"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-flex"
                  :placeholder="$t('Search') + '...'"
                  @input="typing($event)"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-row class="mt-2">
        <b-col cols="4">
          <b-card
            no-body
            class="mb-0"
          >
            <b-table
              ref="refUserListTable"
              striped
              responsive
              class="position-relative"
              :items="respData"
              :fields="tableColumns"
              primary-key="_id"
              show-empty
              :empty-text="$t('No matching records found')"
              style="height: 50vh;"
            >
              <!-- Column: Images -->
              <template #cell(pin)="data">
                <b-row @click="pinChat(data.item)">
                  <feather-icon
                    v-if="data.item.pin"
                    icon="StarIcon"
                    size="18"
                    style="color:red"
                  />
                  <feather-icon
                    v-else
                    icon="StarIcon"
                    size="18"
                    style="color:grey"
                  />
                </b-row>
              </template>
              <template #cell(image)="data">
                <b-row>
                  <b-link @click="openChat(data.item)">
                    <b-avatar
                      size="45px"
                      :src="data.item.userData.image"
                    />
                  </b-link>
                </b-row>
              </template>
              <!-- Column: Images -->
              <template #cell(fullName)="data">
                <b-row>
                  <b-link @click="openChat(data.item)">
                    <b-card-text>{{ data.item.userData.firstName }}
                      {{ data.item.userData.lastName }}</b-card-text>
                  </b-link>
                </b-row>
              </template>
              <!-- Column: message -->
              <template #cell(message)="data">
                <b-row>
                  <b-link @click="openChat(data.item)">
                    <b-card-text>{{
                                   data.item.message.staffId != null
                                     ? $t('Staff')
                                     : $t('Member')
                                 }}
                      {{ $t('Reply') }} :
                      {{
                        data.item.message.type === 0
                          ? data.item.message.message
                          : data.item.message.type === 5 ?
                            $t('Send Link')
                            : $t('Send pictures')
                      }}</b-card-text>
                  </b-link>
                </b-row>
              </template>
              <!-- Column: updated -->
              <template #cell(updated)="data">
                <b-row>
                  <b-link @click="openChat(data.item)">
                    <b-card-text>{{
                      data.item.updated | formatDateTime
                    }}</b-card-text>
                  </b-link>
                </b-row>
              </template>
              <!-- Column: Created -->
              <template #cell(created)="data">
                <b-row>
                  <b-link @click="openChat(data.item)">
                    <b-card-text>{{
                      data.item.created | formatDateTime
                    }}</b-card-text>
                  </b-link>
                </b-row>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">{{ currentPage * perPage - (perPage - 1) }} -
                    {{
                      queriedItems - currentPage * perPage > 0
                        ? currentPage * perPage
                        : queriedItems
                    }}
                    {{ $t('of') }} {{ queriedItems }}</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="queriedItems"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                    @input="nextPage"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col cols="8">
          <div v-if="chatData">
            <b-card
              v-if="$ability.can('read', this.$route.meta.resource)"
              class="chat-widget"
              no-body
            >
              <b-card-header>
                <div class="d-flex align-items-center">
                  <b-avatar
                    size="34"
                    :src="chatData.userInfo.image"
                    class="mr-50 badge-minimal"
                    badge
                    badge-variant="success"
                  />
                  <h5 class="mb-0">
                    {{ chatData.userInfo.firstName }}
                    {{ chatData.userInfo.lastName }}
                  </h5>
                </div>

                <b-navbar-nav class="nav align-items-center ml-auto">
                  <b-nav-item-dropdown
                    class="dropdown-notification mr-25"
                    menu-class="dropdown-menu-media"
                    right
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="18"
                      />
                    </template>
                    <b-dropdown-item
                      link-class="d-flex align-items-center"
                      @click="review"
                    >
                      <feather-icon
                        size="16"
                        icon="StarIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('Change To Review') }}</span>
                    </b-dropdown-item>
                  </b-nav-item-dropdown>
                </b-navbar-nav>
              </b-card-header>

              <section class="chat-app-window">
                <!-- User Chat Area -->
                <vue-perfect-scrollbar
                  ref="refChatLogPS"
                  :settings="perfectScrollbarSettings"
                  class="user-chats scroll-area"
                  style="height: 45vh;"
                >
                  <chat-log
                    :chat-data="chatData"
                    :image-view.sync="imageView"
                    :show-modal.sync="showModal"
                    :profile-user-avatar="menuService == 'franchise'
                      ? getFranchiseData.logo
                      : menuService == 'company'
                        ? getCompanyData.logo
                        : menuService == 'e-commerce'
                          ? getApplicationData.logo
                          : require('@/assets/images/avatars/10.png')
                    "
                  />
                </vue-perfect-scrollbar>

                <!-- Message Input -->
                <b-form
                  class="chat-app-form"
                  @submit.prevent="sendMessage"
                >
                  <b-input-group class="input-group-merge form-send-message mr-1">
                    <!-- <b-img
              v-if="image"
              ref="previewEl"
              class="mr-2"
              rounded
              :src="image"
              height="40"
            /> -->
                    <b-form-input
                      v-model="chatInputMessage"
                      :placeholder="$t('Enter your message')"
                      @input="sendTypingWS($event)"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="info"
                        @click="addLink()"
                      >
                        <feather-icon
                          icon="LinkIcon"
                          size="12"
                        />
                      </b-button>
                      <b-button
                        variant="success"
                        @click="$refs.refInputEl.$el.click()"
                      >
                        {{ $t('Attach file') }}
                      </b-button>
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        {{ $t('Send') }}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-file
                    ref="refInputEl"
                    accept=".webp, .jpeg, .jpg, .png, .gif"
                    :hidden="true"
                    plain
                    @change="inputImageRenderer"
                  />
                </b-form>
              </section>
            </b-card>
            <b-modal
              id="modal-center"
              v-model="showModal"
              centered
              hide-footer
              size="lg"
            >
              <b-img
                :src="imageView != ''
                  ? imageView
                  : require('@/assets/images/blank/no_image.png')
                "
                style="width: 100%;"
                :alt="'Chat'"
              />
            </b-modal>
          </div>
          <div v-else>
            <b-card
              border-variant="primary"
              :header="$t('Chat')"
              header-bg-variant="primary"
              header-text-variant="white"
              align="center"
              style="height: 57vh;"
              class="text-center"
            >
              <b-card-text> {{ $t('Please select a user') }}</b-card-text>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BLink,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BNavItemDropdown,
  BForm,
  BCardHeader,
  BInputGroup,
  BFormFile,
  BNavbarNav,
  BDropdownItem,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/chat'
import debounce from 'debounce'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatLog from './components/ChatLog.vue'
import LinkModal from './LinkModal.vue'

const STORE_MODULE_NAME = 'chat'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BLink,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    BCardHeader,
    BNavbarNav,
    BForm,
    BInputGroup,
    BNavItemDropdown,
    BFormFile,
    BInputGroupAppend,

    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    VuePerfectScrollbar,
    ChatLog,
    LinkModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      currentPageUser: 1,
      searchQueryUser: '',
      perPageUser: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      userId: '',
      isSidebarActive: false,
      sidebarData: {},
      show: false,
      dataId: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      showModal: false,
      chatInputMessage: '',
      image: '',
      imageView: '',
      perPageOptions,
      isLinkModalActive: false,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'pin', label: this.$t('*'), sortable: false },
        { key: 'image', label: this.$t('Image'), sortable: false },
        { key: 'fullName', label: this.$t('Fullname'), sortable: false },
        { key: 'message', label: this.$t('Message'), sortable: false },
        // { key: 'review', label: this.$t('Review'), sortable: false },
        { key: 'updated', label: this.$t('Updated'), sortable: false },
        { key: 'created', label: this.$t('Created'), sortable: false },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0
    },
    userData() {
      return store.state[STORE_MODULE_NAME].userData != null
        ? store.state[STORE_MODULE_NAME].userData.data.map(e => ({
          _id: e.userId,
          name: `${e.firstName} ${e.lastName}`,
        }))
        : []
    },
    queriedItemsUser() {
      return store.state[STORE_MODULE_NAME].userData != null
        ? store.state[STORE_MODULE_NAME].userData.max
        : 0
    },
    wsClient() {
      return store.state.websockets.wsClient[STORE_MODULE_NAME]
    },
    menuService() {
      return store.state.appConfig.menuService
    },
    chatData() {
      return store.state[STORE_MODULE_NAME].chatData != null
        ? store.state[STORE_MODULE_NAME].chatData
        : null
    },
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    authType() {
      if (
        this.getUserData.userType.toUpperCase()
        === this.menuService.toUpperCase()
      ) {
        return this.$t(this.menuService.toUpperCase())
      }
      return `${this.$t(this.menuService.toUpperCase())} (${this.$t(
        this.getUserData.userType.toUpperCase(),
      )})`
    },
    getUserData() {
      return localStorage.getItem('userData') != null
        ? JSON.parse(localStorage.getItem('userData'))
        : null
    },
    getFranchiseData() {
      return localStorage.getItem('franchiseData') != null
        ? JSON.parse(localStorage.getItem('franchiseData'))
        : null
    },
    getCompanyData() {
      return localStorage.getItem('companyData') != null
        ? JSON.parse(localStorage.getItem('companyData'))
        : null
    },
    getApplicationData() {
      return localStorage.getItem('applicationData') != null
        ? JSON.parse(localStorage.getItem('applicationData'))
        : null
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      const Id = to.params.id // ContactID
      if (Id !== 'undefined' && Id !== undefined) {
        // WebSocket Disconnect
        const checkIsHave = store.commit(
          'websockets/checkIsHave',
          STORE_MODULE_NAME,
        )
        if (checkIsHave) {
          store.dispatch('websockets/disconnect', STORE_MODULE_NAME)
        }

        const userData = JSON.parse(localStorage.getItem('userData'))
        const applicationId = localStorage.getItem('applicationId')

        const { userType } = userData
        this.show = true
        this.dataId = Id
        // WebSocket Connect
        const pathname = `/Contact?type=${userType}&applicationId=${applicationId}&userId=${Id}`
        const params = {
          pathname,
          page: STORE_MODULE_NAME,
        }
        store.dispatch('websockets/connect', params)
        const obj = {
          roomChatId: this.dataId,
        }
        this.$forceUpdate()
        store
          .dispatch(`${STORE_MODULE_NAME}/getLogChat`, obj)
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.$nextTick(() => {
              this.psToBottom()
              this.show = false
            })
            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
            // eslint-disable-next-line no-unused-vars
          })
          .catch(error => {
            this.show = false

            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      } else {
        this.$store.commit(`${STORE_MODULE_NAME}/CLEAR_CHAT_DATA`)
      }
    },
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const obj = {
      currentPage: this.currentPage,
      pageSize: this.perPage,
      searchQuery: this.searchQuery,
    }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.getUser()
    this.initChat()
    this.typing = debounce(this.typing, 500)
    this.sendTypingWS = debounce(this.sendTypingWS, 500)
  },
  destroyed() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    // WebSocket Disconnect
    store.dispatch('websockets/disconnect', STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  updated() {
    // WebSocket
    if (this.wsClient) {
      // ถ้าเกิดเชื่อมไม่ได้ จะเข้า onErrorก่อน หลังจากนั้นจะทำการ onClose เอง
      this.wsClient.onerror = err => {
        console.log('onError')
        console.log(err)
      }
      this.wsClient.onclose = err => {
        console.log('onClose')
        console.log(err)
      }
      this.wsClient.onmessage = msg => {
        const SocketMessage = JSON.parse(msg.data)

        console.log(SocketMessage)
        if (SocketMessage.message !== 'WS_CONNECTED') {
          if (
            SocketMessage.data.method !== 'VIEW'
            && SocketMessage.data.method !== 'UPDATE'
          ) {
            store.commit(
              `${STORE_MODULE_NAME}/SEND_MESSAGE`,
              SocketMessage.data.send.data,
            )
            this.$nextTick(() => {
              this.psToBottom()
            })
          }
        }
      }
    }
  },
  methods: {
    initChat() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const applicationId = localStorage.getItem('applicationId')
      const Id = this.$route.params.id // ContactID
      if (Id !== 'undefined' && Id !== undefined) {
        const { userType } = userData
        this.show = true
        this.dataId = Id
        // WebSocket Connect
        const pathname = `/Contact?type=${userType}&applicationId=${applicationId}&userId=${Id}`
        const params = {
          pathname,
          page: STORE_MODULE_NAME,
        }
        store.dispatch('websockets/connect', params)
        const obj = {
          roomChatId: this.dataId,
        }
        store
          .dispatch(`${STORE_MODULE_NAME}/getLogChat`, obj)
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.$nextTick(() => {
              this.psToBottom()
              this.show = false
            })
            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
            // eslint-disable-next-line no-unused-vars
          })
          .catch(error => {
            this.show = false

            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
    },
    inputImageRenderer(input) {
      this.show = true

      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        // if (input.target.files[0].size > 300000) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     position: 'bottom-right',
        //     props: {
        //       title: this.$t('Error'),
        //       icon: 'ErrorIcon',
        //       variant: 'danger',
        //       text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
        //     },
        //   })
        //   return
        // }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          const obj = {
            roomChatId: this.dataId,
            image: this.image,
            userId: this.chatData.userInfo.userId,
            type: 1,
          }
          store
            .dispatch(`${STORE_MODULE_NAME}/uploadImage`, obj)
            // eslint-disable-next-line no-unused-vars
            .then(result => {
              this.chatInputMessage = ''

              // Update scroll position
              // Scroll to bottom
              this.$nextTick(() => {
                this.psToBottom()
                this.show = false
              })
              // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
            })
            .catch(error => {
              this.show = false
              console.log('fetchUsers Error : ', error)
              // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
            })
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      } else {
        this.show = false
      }
    },
    review() {
      this.show = true

      const obj = {
        roomChatId: this.dataId,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/setInReview`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    sendTypingWS(value) {
      console.log(value)
      if (value.trim() !== '') {
        const dataSend = {
          data: null, // ข้อมูลที่ส่ง
          page: 'CONTACT_US_ECOMMERCE', // ชื่อเมนู
        }
        const webSocketData = {
          send: dataSend, // ข้อมูลที่จะส่ง ห้ามเป็น Array [Map]
          // ส่งไปหา users(ผู้ใช้ทั้งหมดที่ออนไลน)
          // users(ผู้ใช้งานทั้งหมด)
          // root(ผู้ดูแลระบบทั้งหมด)
          // agents(ตัวแทนทั้งหมด)
          // members(คนขับทั้งหมด)
          // driver(คนขับทั้งหมด)
          // web(เว็บทั้งหมด)
          // mobile(มือถือทั้งหมด)
          // ถ้าจะส่งหา USER แยก จะใช้ USER_{ObjectID ของผู้ใช้ที่จะส่ง} [refer_users] [USER_61554f40a0f9d6f42808655e]
          // ถ้าจะส่งหา Call a car แยก จะใช้ CALL_{ObjectID ของการเรียกรถ} [refer_call_a_car] [CALL_61554f40a0f9d6f42808655e]
          // ถ้าจะส่งหา Contact Us แยก จะใช้ CONTACT_{ ObjectID ของการสนทนา } [refer_contact_us] [CONTACT_61554f40a0f9d6f42808655e]
          author: [`CONTACT_${this.dataId}`], // Example author: ['root','agents','USER_61554f40a0f9d6f42808655e'],
          method: 'VIEW', // ['ADD','DELETE','UPDATE','VIEW']
          sendMethod: 'WAIT_DATA', // WAIT_DATA รอข้อมูล เช่น กำลังตอบกลับ แต่ไม่ได้ส่งอะไรมาใน send['data'] ด้วย , SEND_DATA ส่งข้อมูล เช่น เก็บข้อมูล ส่งข้อมูลมาใน send['data'] ด้วย

          // ผู้ส่งไป
          type: 'company', //  Web :['root','agents'] , Moblie :['member','driver'] *เอาไว้กันลืม [String]
          pathname: 'Web', // ['Web','Mobile'] *เอาไว้กันลืม [String]
        }
        const obj = {
          data: webSocketData,
          page: STORE_MODULE_NAME,
        }
        store.dispatch('websockets/sendMessage', obj)
      }
    },
    sendMessage() {
      if (this.chatInputMessage.length > 0) {
        this.show = true

        const obj = {
          roomChatId: this.dataId,
          message: this.chatInputMessage,
          userId: this.chatData.userInfo.userId,
          type: 0,
        }
        store
          .dispatch(`${STORE_MODULE_NAME}/sendMessage`, obj)
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.chatInputMessage = ''

            // Update scroll position
            // Scroll to bottom
            this.$nextTick(() => {
              this.psToBottom()
              this.show = false
            })
            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          })
          .catch(error => {
            this.show = false
            console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
    },
    psToBottom() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
    createChat() {
      if (this.userId !== '' && this.userId !== null) {
        this.show = true
        store
          .dispatch(`${STORE_MODULE_NAME}/createChat`, { userId: this.userId })
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.show = false
            const { data } = result.data
            this.$router.push({ path: `/e-commerce/chat/${data._id}` })

            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
            // eslint-disable-next-line no-unused-vars
          })
          .catch(error => {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
    },
    onSearchUser(query) {
      this.searchQueryUser = query
      this.currentPageUser = 1
      this.getUser()
    },

    nextPageUser(page) {
      this.currentPageUser = page
      this.userId = ''
      this.getUser()
    },
    getUser() {
      const obj = {
        currentPage: this.currentPageUser,
        pageSize: this.perPageUser,
        searchQuery: this.searchQueryUser,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/getUser`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    pinChat(data) {
      const id = data._id
      const status = !data.pin
      const obj = {
        id,
        status,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/pinChat`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          // this.$toast({
          //   component: ToastificationContent,
          //   position: 'bottom-right',
          //   props: {
          //     title: this.$t('Error'),
          //     icon: 'ErrorIcon',
          //     variant: 'danger',
          //     text: this.$t(error.response.data.message),
          //   },
          // })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    openChat(data) {
      this.$router.push({ path: `/e-commerce/chat/${data._id}` })
    },
    typing(value) {
      console.log(value)
      this.searchQuery = value
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, id)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nextPage(page) {
      this.currentPage = page
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
    addLink() {
      this.isLinkModalActive = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/base/pages/app-chat-list.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
