<template>
  <b-modal :visible="isLinkModalActive" :title="$t('Link')" ok-title="Accept" size="lg"
    @change="(val) => $emit('update:is-link-modal-active', val)">
    <div class="w-100">
      <div class="w-100">
        <b-form-input class="w-100" v-model="link" :placeholder="$t('Link')" />
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <div class="float-right">
          <b-button @click="saveLink" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success">
            {{ $t('Send') }}
            <feather-icon icon="LinkIcon" size="12" />
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>

import {
  BModal,
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [],
  model: {
    prop: 'isLinkModalActive',
    event: 'update:is-link-modal-active',
  },
  props: {
    dataId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    storeModuleName: {
      type: String,
      required: true,
    },
    isLinkModalActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      link: '',
      required,
    }
  },
  computed: {},
  watch: {
  },
  methods: {
    initValues() {
      this.link = ''
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    saveLink() {
      if (this.link.length > 0) {
        const obj = {
          roomChatId: this.dataId,
          message: this.link,
          userId: this.userId,
          type: 5,
        }

        store
          .dispatch(`${this.storeModuleName}/sendMessage`, obj)
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            console.log('result', result)
            this.link = ''
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
