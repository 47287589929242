import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    chatData: null,
    userData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_USER(state, payload) {
      state.userData = payload
    },
    GET_LOG_CHAT(state, payload) {
      state.chatData = payload
    },
    ADD(state, payload) {
      state.respData.data.unshift(payload)
    },
    SEND_MESSAGE(state, payload) {
      const productIndex = state.chatData.data.findIndex(p => p._id === payload._id)
      if (productIndex < 0) {
        state.chatData.data.push(payload)
      }
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    UPDATE_PIN(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload.id)
      state.respData.data[productIndex].pin = payload.status
      // console.log(productIndex, state.respData.data[productIndex].pin, payload.status)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
    CLEAR_CHAT_DATA(state) {
      state.chatData = null
    },
    UPDATE_CHAT(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload.roomChatId)
      // Object.assign(state.respData.data[productIndex], payload)
      if (productIndex > -1) {
        const obj = {
          _id: payload.roomChatId,
          created: payload.created,
          updated: payload.updated,
          message: {
            message: payload.message,
            staffId: payload.staffId,
            type: payload.type,
          },
          userData: {
            firstName: state.respData.data[productIndex].userData.firstName,
            image: state.respData.data[productIndex].userData.image,
            lastName: state.respData.data[productIndex].userData.lastName,
            userId: state.respData.data[productIndex].userData.userId,
            _id: state.respData.data[productIndex].userData._id,
          },
        }
        state.respData.data.splice(productIndex, 1)

        state.respData.data.unshift(obj)
      } else {
        const obj = {
          _id: payload.roomChatId,
          created: payload.created,
          updated: payload.updated,
          message: {
            message: payload.message,
            staffId: payload.staffId,
            type: payload.type,
          },
          userData: {
            firstName: payload.userData.firstName,
            image: payload.userData.image,
            lastName: payload.userData.lastName,
            userId: payload.userData.userId,
            _id: payload.userData._id,
          },
        }
        state.respData.data.unshift(obj)
      }
    },
  },
  actions: {
    pinChat({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post(`/api/web/application/eCommerce/chat/pin/${item.id}`, item)
          .then(response => {
            commit('UPDATE_PIN', item)
            resolve(response)
          })
          .catch(error => {
            console.log('error', error)
            reject(error)
          })
      })
    },
    getUser({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/chat/getUser?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_USER', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    createChat({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/chat/createChat', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/chat/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getLogChat({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/chat/getLogChat?roomChatId=${item.roomChatId}`)
          .then(response => {
            commit('GET_LOG_CHAT', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    sendMessage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/chat/sendMessage', item)
          .then(response => {
            commit('SEND_MESSAGE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    setInReview({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/chat/setInReview', item)
          .then(response => {
            // commit('SEND_MESSAGE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    uploadImage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/chat/uploadImage', item)
          .then(response => {
            // commit('SEND_MESSAGE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
