<template>
  <div class="chats">
    <div v-for="(msgGrp, index) in formattedChatData.msgGroup" :key="msgGrp.userId + String(index)" class="chat"
      :class="{ 'chat-left': msgGrp.staffId === null }">
      <div class="chat-avatar">
        <b-avatar size="36" class="avatar-border-2 box-shadow-1" variant="transparent"
          :src="msgGrp.userId === formattedChatData.userInfo.userId ? formattedChatData.userInfo.image : profileUserAvatar" />
      </div>
      <div class="chat-body">
        <div class="chat-content">
          <div v-if="msgGrp.type === 0">
            <p>{{ msgGrp.messages }}</p>
          </div>
          <div v-if="msgGrp.type === 1">
            <b-img v-if="msgGrp.messages" :src="msgGrp.messages" fluid thumbnail width="100px" :alt="'Chat_' + index"
              class="cursor-pointer" @click="viewImage(msgGrp.messages)" />
            <b-img v-else :src="require('@/assets/images/blank/no_image.png')" fluid thumbnail width="100px"
              :alt="'Chat_' + index" />
          </div>
          <div v-if="msgGrp.type === 4">
            <audio controls>
              <source :src="msgGrp.messages" type="audio/mp4">
              Your browser does not support the audio tag.
            </audio>
          </div>
          <div v-if="msgGrp.type === 5">
            <a :href="msgGrp.messages" target="_new">
              <p style="color: white;">{{ msgGrp.messages }}</p>
            </a>
          </div>
          <p>{{ msgGrp.created | formatDateTime }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BAvatar, BImg } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BImg,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
    imageView: {
      type: String,
      required: false,
    },
    showModal: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      displayModal: false,
    };
  },
  methods: {
    viewImage(image) {
      this.$emit('update:imageView', image)
      this.$emit('update:showModal', true)
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const userInfo = {
        id: props.chatData.userInfo._id,
        userId: props.chatData.userInfo.userId,
        image: props.chatData.userInfo.image,
      }

      let chatLog = []
      if (props.chatData.data) {
        chatLog = props.chatData.data
      }
      const msgGroup = []

      // eslint-disable-next-line no-unused-vars
      chatLog.forEach((msg, index) => {
        if (msg.staffId != null) {
          msgGroup.push({
            userId: null,
            staffId: msg.staffId,
            messages: msg.message,
            type: msg.type,
            created: msg.created,
          })
        } else {
          msgGroup.push({
            userId: msg.userId,
            staffId: null,
            messages: msg.message,
            type: msg.type,
            created: msg.created,
          })
        }
      })
      return {
        msgGroup,
        userInfo,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
    }
  },
}
</script>

